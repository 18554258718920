import { useEffect } from 'react';

import '../i18n';
import TagManager from 'react-gtm-module';

import { AppWrapper } from '../context/state';
import '../components/assets/styles/compiledStyles.scss';

function Application({ Component, pageProps }) {

  useEffect(() => {
    TagManager.initialize({ gtmId: `${process.env.NEXT_PUBLIC_TAG_MANAGER_TRACKER}` });
    console.log('1, app.js');
  }, []);

  return (
    <AppWrapper>
      <Component {...pageProps} />
    </AppWrapper>
  )
}

export default Application;
